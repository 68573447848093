import { signal } from '@preact/signals';

export type UserLoginState = 'login' | 'logout' | 'forget' | 'uninitialized';
export type User = {
	uid: number | undefined;
	state: UserLoginState;
};
export interface UserEvent extends Event {
	detail: { uid: number | undefined; state: UserLoginState };
}

declare global {
	interface WindowEventMap {
		login: UserEvent;
		badge: UserEvent;
		logout: UserEvent;
		forget: UserEvent;
	}

	/**
	 * https://gitlab.seznam.net/rus/login/-/blob/master/src/static/js/api/3.1/src/user.ts
	 */
	type LoginState = 'login' | 'logout' | 'forget';

	/**
	 * https://gitlab.seznam.net/rus/login/-/blob/master/src/static/js/api/3.1/src/conf.ts
	 */
	type LoginCfg = Partial<{
		lang: string;
		log: (...args: any[]) => void;
		oauth: Partial<{
			client_id: string;
			redirect_uri: string;
			state: string;
		}>;
		popup: boolean;
		serviceId: string;
		source: string;
		sync: boolean;
		accountHost: string;
		ignoreRemoteSwitch: boolean;
		loginHost: string;
		messHost: string;
		notificationsHost: string;
		registerHost: string;
		serviceHost: string;
		switchUsers: boolean;
		uid: number | null;
	}>;

	type LoginLinkType = 'oauth' | 'register' | 'relogin' | 'assignEmail';
	interface LoginOptions {
		type?: LoginLinkType;
		username?: string;
		sourceComponent?: string;
	}

	interface Window {
		login: {
			version: string;
			cfg: (c: LoginCfg) => void;
			link: (a?: HTMLAnchorElement, options?: LoginOptions) => HTMLAnchorElement;
			open: (options?: LoginOptions | string | null, legacyUsername?: string) => globalThis.Window | null;
			close: () => void;
			register: {
				open: (options?: LoginOptions) => globalThis.Window | null;
				link: (a?: HTMLAnchorElement, options?: LoginOptions) => HTMLAnchorElement;
			};
			oauth: {
				open: () => globalThis.Window | null;
				link: (a?: HTMLAnchorElement) => HTMLAnchorElement;
			};
			assignEmail: {
				open: (options?: LoginOptions) => globalThis.Window | null;
				link: (a?: HTMLAnchorElement, loginOptions?: LoginOptions) => HTMLAnchorElement;
			};
			updateDialog: {
				show: () => Promise<void>;
			};
			autologin: () => boolean;
			socialToken: {
				get: () => Promise<string>;
			};
			current: {
				state: LoginState | null;
				uid: number | null;
				premium: boolean;
				/**
				 * Seznam bez reklam
				 */
				sbr: boolean;
				advert_uid: string | null;
				pid: string | null;
				adminName: string;
				others: {
					advert_uid: string;
					state: LoginState;
				}[];
				euconsent: string | null;
				encodedFlags: string;
			};
		};
	}
}

type LoginCallback = { callback: () => void; resetOnAccountSwitch?: boolean };

let currentUser: User = { uid: undefined, state: 'uninitialized' };
if (globalThis?.window && globalThis.window.login) {
	const windowUser = globalThis.window.login.current;
	if (windowUser?.uid && windowUser?.state) {
		currentUser = { uid: windowUser.uid, state: windowUser.state };
	}
}
export const user = signal<User>(currentUser);
const afterLoginCallback = signal<LoginCallback>();

if (globalThis?.window) {
	globalThis.window.addEventListener('login', (event) => {
		// Reset after login callback on account switching
		if (user.value?.uid && user.value?.uid !== event.detail.uid && afterLoginCallback.value?.resetOnAccountSwitch) {
			afterLoginCallback.value = undefined;
		}

		user.value = { uid: event.detail.uid, state: event.detail.state };

		if (afterLoginCallback.value) {
			afterLoginCallback.value.callback();
			afterLoginCallback.value = undefined;
		}
	});
	globalThis.window.addEventListener('badge', (event) => {
		user.value = { uid: event.detail.uid, state: event.detail.state };
	});
	globalThis.window.addEventListener('logout', (event) => {
		user.value = { uid: event.detail.uid, state: event.detail.state };
	});
	globalThis.window.addEventListener('forget', (event) => {
		user.value = { uid: event.detail.uid, state: event.detail.state };
	});

	globalThis.window.login?.cfg({ serviceId: 'sbazar' });
}

export const loginOpen = (loginOptions?: LoginOptions, callback?: LoginCallback) => {
	const { login } = window;

	afterLoginCallback.value = callback;

	if (login) {
		const isOpen = login.open(loginOptions);

		if (!isOpen) {
			// Když se nepodaří otevřít přihlašovací okno,
			// tak přesměruje na přihlašovací stránku.
			location.href = login.link(undefined, loginOptions).href;
		}
	}
};
